import { GetServerSidePropsContext } from 'next';
import NeoGateway from '@anghami/neogateway';
import getUserOwnProfileData, {
	IGetOwnProfileDataResponse
} from '@anghami/neogateway/dist/endpoints/getOwnProfileData';
import cookie from '@helpers/cookies';
import { environment } from '@environment';
import { setLanguageCookies, getSidFromUrl } from '@helpers/helper';

const isSupportedLang = (lang: string) => ['ar', 'en', 'fr'].includes(lang);
const defaultLang = 'en';

export let gateway: NeoGateway;

const getApiUrl = () => {
	const isServer = typeof window === 'undefined';
	const isPreviewOrLocal = !isServer && location.host.includes('angha.me');
	const isHttps = !isServer && location.href.includes('https');
	const isDev = process.env.NODE_ENV === 'development';
	const isProduction = process.env.POD_NAMESPACE === 'production';
	console.log('POD_NAMESPACE', process.env.POD_NAMESPACE);

	// if I'm on the client side and I'm on the local or preview environment use the angha.me api endpoint
	if (isDev || isPreviewOrLocal) {
		return isHttps ? environment.local_https_api_endpoint : environment.local_api_endpoint;
	}

	if (isServer) {
		// if I'm on the server side and I'm on the same cluster use the server api endpoint, else use the load balancer api endpoint
		return isProduction ? environment.server_api_endpoint : environment.prod_api_endpoint;
		// TODO: check with charbel why this is not working
		// : environment.server_api_endpoint_with_load_balancer;
	}

	// if I'm on the client side and I'm on the production environment use the production api endpoint
	return environment.prod_api_endpoint;
};

const initGateway = async (SID, lang, customHeaders = null) => {
	const apiURL = getApiUrl();
	console.log('--apiURL => ', apiURL);
	const gatewayInstance = new NeoGateway({
		autoloadSodium: false,
		catchURLFromEnv: false,
		apiURL,
		appSid: SID,
		customParams: {
			web_medium: 'web',
			lang,
			userlanguageprod: lang,
			language: lang
		},
		...(customHeaders && { customHeaders })
	});

	try {
		await gatewayInstance.ready();
	} catch (err) {
		console.log('--Fail to init neogateway err => ', err);
	}

	return gatewayInstance;
};

export const initNeoGatewayServer = async (
	context: GetServerSidePropsContext,
	disableServerAuth = false
) => {
	cookie.reInitWithContext(context);
	// sometimes we get 2 sid query params, handle it
	const sidFromQueryParam = Array.isArray(context?.query?.sid)
		? context?.query?.sid[0]
		: context?.query?.sid;
	const appsidFromQueryParam = Array.isArray(context?.query?.appsid)
		? context?.query?.appsid[0]
		: context.query?.appsid;
	const SIDFromUrl =
		sidFromQueryParam ||
		appsidFromQueryParam ||
		cookie.getCookie('appsidsave') ||
		cookie.getCookie('sid');
	const sss = cookie.getCookie('sss') || null;

	disableServerAuth = context.query.disableServerAuth
		? Boolean(context.query.disableServerAuth.toString())
		: disableServerAuth;

	let userlanguageprod =
		context?.query?.lang?.toString() || cookie.getCookie('userlanguageprod') || 'en';

	if (!isSupportedLang(userlanguageprod?.toString() || defaultLang)) {
		userlanguageprod = defaultLang;
	}

	setLanguageCookies(userlanguageprod, null);

	context.locale = userlanguageprod;

	const customHeaders = {
		origin: context.req.headers.host,
		'user-agent': context.req.headers['user-agent'],
		intra: 'true',
		cookie:
			context.req.headers.cookie ||
			(SIDFromUrl ? `appsidsave=${SIDFromUrl};appsid=${SIDFromUrl};` : '') +
				`userlanguageprod=${userlanguageprod};language=${userlanguageprod}`
	};

	const gatewayInstance = await initGateway(SIDFromUrl, userlanguageprod, customHeaders);
	const isDarkMode = Boolean(Number(context?.query?.dark_mode || '0'));
	const isInnerWeb = Boolean(Number(context?.query?.innerweb || '0'));

	if (!disableServerAuth && (sss || SIDFromUrl)) {
		let user: IGetOwnProfileDataResponse = null;
		try {
			const response = await gatewayInstance.callEndpoint(getUserOwnProfileData, {});
			user = response.data;
		} catch (_) {}

		return {
			gateway: gatewayInstance,
			pageProps: {
				userProfile: user || null,
				SID: SIDFromUrl || null,
				sss: sss || null,
				lang: userlanguageprod || 'en',
				isDarkMode,
				isInnerWeb
			}
		};
	}

	return {
		gateway: gatewayInstance,
		pageProps: {
			lang: userlanguageprod || 'en',
			SID: SIDFromUrl || null,
			sss: sss || null,
			isDarkMode,
			isInnerWeb
		}
	};
};

export const initNeoGatewayBrowser = async (SID: string, lang: string) => {
	if (gateway) {
		return gateway;
	}

	const SIDFromUrl = getSidFromUrl();
	const SIDFromLocalStorage = JSON.parse(localStorage.getItem('user'))?.socketsessionid;
	gateway = await initGateway(SIDFromUrl || SID || SIDFromLocalStorage, lang);
	return gateway;
};
