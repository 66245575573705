const environment = {
	prod_api_endpoint: 'https://api.anghami.com/gateway.php',
	lab_api_endpoint: 'http://lab.anghami.com/gateway.php',
	local_https_api_endpoint: 'https://api.angha.me/gateway.php',
	local_api_endpoint: 'http://api.angha.me/gateway.php',
	server_api_endpoint: 'http://api-default.production.svc.cluster.local/gateway.php',
	server_api_endpoint_with_load_balancer: 'https://api.internal.angha.me',
	koussa_osn_api_endpoint: 'https://koussa-osn-grpc.anghami.com',
	ganalyticsKey: 'UA-38735485-5',
	gtagKey: 'UA-125682597-1',
	fbkKey: '355354294849193',
	fbSDK: '299953330100883',
	amplitudeKey: '7a16172fd03f9288cc765d3224675bbe',
	gSiteTagKey: 'G-THZZEFMK7S',
	raygun: 'hHqNnxcpbUQ4TIwNPDMeA',
	snapchatPixelKey: 'a638b9be-8f25-44f7-8895-cf0cd5d94baa',
	merchant_info: {
		AE: {
			checkout_public_key: 'pk_a5ab5910-0b91-4f9f-935b-7404e8629f39',
			merchant_id: 'merchant.com.ae.anghami'
		},
		default: {
			checkout_public_key: 'pk_7a642e0d-5152-4f74-b19a-0974b14ddef8',
			merchant_id: 'merchant.com.anghami'
		},
		XX: {
			checkout_public_key: 'pk_test_3cf07dac-9221-4c34-a991-d045e8966256',
			merchant_id: 'merchant.sandbox.com.anghami'
		},
		DD: {
			checkout_public_key: 'pk_76e2c257-a1d1-4c0e-98ea-b6e0ac2dfd15',
			merchant_id: 'merchant.com.anghami'
		},
		US: {
			checkout_public_key: 'pk_875f66fe-d5d3-402c-bc26-4f2d046d5e0e',
			merchant_id: 'merchant.com.anghami'
		}
	},
	checkout_url: 'https://api2.checkout.com/v2/tokens/card',
	checkout_sandbox_url: 'https://sandbox.checkout.com/api2/v2/tokens/card',
	fullpage_react_key: '61973BD3-91EC460E-9FFDF19F-7A6195F9',
	phoenix_url: 'https://phoenix.anghcdn.co',
	angimg_cdn_url: 'https://angimg.anghcdn.co',
	web_cdn_url: 'https://cdnweb.anghami.com/',
	artwork_cdn_url: 'https://artwork.anghcdn.co',
	new_web_cdn_url: 'https://cdnweb.anghami.com',
	web2_images_cdn_url: 'https://cdnweb.anghami.com/web2/assets/img',
	images_cdn_url: 'https://cdnweb.anghami.com/_next/static/images',
	cdn_url_static: 'https://cdnweb.anghami.com/_next/static',
	google_maps_api_key: 'AIzaSyCFTHar59j9z5PCk49gTBzQhLZgD5NcSnU',
	amazon_client_id: 'amzn1.application-oa2-client.e10f091e468842b69433dc4beb0d82de',
	amazon_sdk_url: 'https://assets.loginwithamazon.com/sdk/na/login1.js',
	google_client_id: '1074302196996-p9br5pi89kdo5h2f8mqg88e01hqetlip.apps.googleusercontent.com',
	google_sdk_url: 'https://apis.google.com/js/platform.js?onload=onGoogleLoginReady', // Don't change the onload method
	facebook_app_id: '299953330100883',
	facebook_sdk_url: 'https://connect.facebook.net/en_US/sdk/debug.js',
	apple_sdk_url:
		'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
	apple_client_id: 'com.anghami.signinservice',
	tamara_credentials: {
		sandbox: {
			script_url: 'https://cdnweb.anghami.com/web/assets/js/tamara-new-widget.min.js',
			public_key: 'e283d9a8-379a-4ce8-b43b-44838daa1dd5'
		},
		production: {
			script_url: 'https://cdn.tamara.co/widget/installment-plan.min.js',
			public_key: ''
		}
	},
	BRANCH_APP_KEY: 'key_live_nn77Ke1utzUBGKSPAQoudmkcCCjJQ2cX'
};

module.exports = {
	environment
};
